import { InjectionToken } from '@angular/core';

export interface DexieConfiguration {
    databaseName: string;
    databaseVersion: number;
}

export const DEXIE_CONFIG = new InjectionToken<DexieConfiguration>('DEXIE_CONFIG');

export const DexieConfig: DexieConfiguration = { databaseName: 'Commission', databaseVersion: 8 };
