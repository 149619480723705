import { createSelector } from '@ngrx/store';

import { ControllerConfigurationInfo, Element } from '@priva/next-model';

import { BuildingSelectors } from 'app/solution/building/state';
import { ElementsStateContainer } from 'app/solution/elements/state';

import { CxssConfigurationStateContainer } from './configuration.state';

export const selectConfigurations = (state: CxssConfigurationStateContainer & ElementsStateContainer) =>
    state.cxssConfiguration.latestConfigurationInfos;

export const selectLatestConfiguration = createSelector(
    BuildingSelectors.selectSelectedElement,
    selectConfigurations,
    (device?: Element, configurations?: ControllerConfigurationInfo[]) => {
        if (!device) {
            return undefined;
        }
        return configurations?.find((p) => p.controllerId === device.id);
    },
);
