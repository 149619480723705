import { Inject, Injectable } from '@angular/core';
import { Dexie } from 'dexie';

import { ControllerConfigurationInfo, ControllerConfigurationResource } from '@priva/next-model';

import { ConfigurationQuery } from 'app/common/local-storage/local-storage.model';

import { DEXIE_CONFIG, DexieConfiguration } from './provider/dexie.config';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationLocalStorageService {
    private readonly configurationTable = 'configuration';
    private readonly configurationResourceTable = 'configurationResource';

    constructor(
        private db: Dexie,
        @Inject(DEXIE_CONFIG) private dexieConfiguration: DexieConfiguration,
    ) {
        this.initDB();
    }

    /* istanbul ignore next wvk, only table creation */
    public initDB(): void {
        // Name only the indexed columns in the store definition
        this.db.version(this.dexieConfiguration.databaseVersion).stores({
            configuration:
                '++id, solutionId, controllerId, version, [solutionId+controllerId+version], [solutionId+version]',
            configurationResource:
                '++id, solutionId, controllerId, version, [solutionId+controllerId+version], [solutionId+version], [solutionId+controllerId], [solutionId+controllerId+configurationId]',
        });
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public async exists(solutionId: string): Promise<boolean> {
        const resource = await this.db.table(this.configurationTable).where({ solutionId }).first();
        return !!resource;
    }

    /* istanbul ignore next wvk, simple return */
    public async getLatest(solutionId: string): Promise<ControllerConfigurationInfo[]> {
        return this.db.table(this.configurationTable).where({ solutionId }).toArray();
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public async getResources(query: ConfigurationQuery): Promise<ControllerConfigurationResource[]> {
        return this.db.table(this.configurationResourceTable).where(query).toArray();
    }

    /* istanbul ignore next wvk, only wrapping Dexie */
    public async put(solutionId: string, configurations: ControllerConfigurationInfo[]): Promise<void> {
        await this.db.table(this.configurationTable).where({ solutionId }).delete();
        const configurationsPut = configurations.map((c) => ({ solutionId, ...c }));
        await this.db.table(this.configurationTable).bulkPut(configurationsPut);
    }

    public async putResources(
        query: ConfigurationQuery,
        resources: ControllerConfigurationResource[],
    ): Promise<unknown> {
        const resourcesPut = resources.map((resource) => ({ solutionId: query.solutionId, ...resource }));

        await this.delete(query);
        return this.db.table(this.configurationResourceTable).bulkPut(resourcesPut);
    }

    public async delete(query: ConfigurationQuery): Promise<void> {
        await this.db.table(this.configurationResourceTable).where(query).delete();
    }
}
