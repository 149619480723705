import { createAction, props } from '@ngrx/store';

import { ControllerConfigurationInfo } from '@priva/next-model';

export const getAllLatestGeneratedConfigurations = createAction(
    '[Controller Configuration API] Get latest generated configurations of all controllers',
    props<{ solutionId: string }>(),
);
export const getAllLatestGeneratedConfigurationsSuccess = createAction(
    '[Controller Configuration API] Get latest generated configurations of all controllers (success)',
    props<{ solutionId: string; configurationInfos: ControllerConfigurationInfo[] }>(),
);

export const getLatestGeneratedConfiguration = createAction(
    '[Controller Configuration API] Get latest generated configuration of controller',
    props<{ controllerId: string }>(),
);

export const getLatestGeneratedConfigurationSuccess = createAction(
    '[Controller Configuration API] Get latest generated configuration of controller (success)',
    props<{ solutionId: string; controllerId: string; configurationInfos: ControllerConfigurationInfo[] }>(),
);

export const downloadConfigurations = createAction(
    '[Controller Configuration API] Download configurations',
    props<{ solutionId: string; configurationInfos: ControllerConfigurationInfo[] }>(),
);
export const downloadConfigurationsSuccess = createAction(
    '[Controller Configuration API] Download configurations (success)',
    props<{ solutionId: string; configurationInfos: ControllerConfigurationInfo[] }>(),
);
export const downloadConfigurationsFailed = createAction(
    '[Controller Configuration API] Download configurations (failed)',
    props<{ error: string }>(),
);

export const getControllerConfigurationValidation = createAction(
    '[Controller Configuration API] Get controller configuration validation',
    props<{ controllerId: string }>(),
);
export const getControllerConfigurationValidationSuccess = createAction(
    '[Controller Configuration API] Get controller configuration validations (success)',
    props<{ controllerId: string; validationResults: string[] }>(),
);
export const clearControllerConfigurationValidation = createAction(
    '[Controller Configuration] Clear controller configuration validation',
);
