import { ControllerConfigurationInfo } from '@priva/next-model';

export interface CxssConfigurationStateContainer {
    cxssConfiguration?: CxssConfigurationState;
}

export interface CxssConfigurationState {
    latestConfigurationInfos: ControllerConfigurationInfo[];
    validationResults?: string[];
}

export const initialConfigurationState: CxssConfigurationState = {
    latestConfigurationInfos: [],
};
